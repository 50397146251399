body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  background-color: #f9f9f9;
  min-width: 690px;
}

.App-header {
  background-color: #282c34;
  height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position:  relative;
}

.logo-right{
  position: absolute;
  top: auto;
  right: 2vh;
}

.logo-right-img{
  height: 1.5em;
  width: auto;
}

.logo-left{
  position: absolute;
  top: auto;
  left: 2vh;
}

.logo-left-img{
  width: auto;
  height: 1.3em;
	vertical-align: sub;
}

.main{
  height: 90vh;
}

.alert{
  float: left;
  width: 100%;
}

.main_loading{
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
}

footer{
  background-color: #282c34;
  color: rgb(200, 200, 200);
  height: 2vh;
  font-size: 0.65em;
  clear: both;
  position: relative;
  //margin-top: -2vh;
}

footer p{
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-55%);
  margin: 0;

}

/* ----- FILTER COLUMN ----- */

.filter-column{
  text-align: left;
  padding-top: 2vh;
  padding-bottom: 2vh;
  height: 90vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 0.8vh !important;
  margin-right: 0.1vh;
  min-width: 137px;
}

.filter-column h5{
  border-bottom: 1px solid #ededed;
  padding-bottom: 0.7vh;
  margin-bottom: 2vh;
  width: 4.2em;
}

.filter-column .content{
  padding: 1.5vh;
  background-color: white;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  //height: 89vh;
  -webkit-box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
  -moz-box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
  box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
}

.filter_form{
  font-size: 0.8em;
  //max-width: 100%;
  //height: 93%;
  //overflow-x: hidden;
  //overflow-y: scroll;
  //padding-right: 1vh;
}

.filter-column::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.9);
	border-radius: 5px;
	background-color: #F5F5F5;
  margin-top:2vh;
  margin-bottom:2vh;
}

.filter-column::-webkit-scrollbar
{
	width: 5px;
}

.filter-column::-webkit-scrollbar-thumb
{
  border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
	background-color: #999;
}

.form-check-input, .form-check-label{
  cursor: pointer;
}

.filterCheckCollapseButton{
  color: #07c;
  font-size: 0.9em;
  cursor: pointer;
  margin: 0.4em 0em;
}

.form-label{
  margin-bottom: 0;
}

.form-label h6{
  margin-top: 1vh;
}
.addInfo{
  font-size: 0.9em;
  font-weight: lighter;
}

.form-label.break{
  margin-bottom: .2vh;
}

.form-label.bottomSpace{
  margin-bottom: 0.6vh;
}

/*
.form-group.row{
  margin-top:1vh;
  //width: 100%;
} */

.smallLabel{
  padding: 0.1vh 1.3vh 0.3vh;

}

.numberFilter label{
  padding-right: 0.5em;
}

.numberFilter div:first-of-type{
  padding: 0;
  display: flex;
  align-items: center
}

.numberFilter div:nth-of-type(2){
  padding-left: 0.5em;
  display: flex;
  align-items: center
}

.rangeFilter{
  padding-left: 0.7vh;
  padding-right: 0.7vh;
}

.filterSlider{
  width: 80%;
  margin: auto;
}

.MuiSlider-markLabel
{
  font-size: 0.9em !important;
  opacity: 0.5;
}

#slider-cost .PrivateValueLabel-circle-33,
#slider-cost .jss33 {
  width: 42px;
  height: 42px;
}

 /* ----- DETAIL COLUMN ----- */

.detail-column{
  /* border-left: 1px solid;
  border-right: 1px solid; */
  height: 90vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 0.7vh !important;
  //margin-right: 0.8vh;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.detail-column::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.9);
	border-radius: 5px;
	background-color: #F5F5F5;
  margin-top:2vh;
  margin-bottom:2vh;
}

.detail-column::-webkit-scrollbar
{
	width: 5px;
}

.detail-column::-webkit-scrollbar-thumb
{
  border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
	background-color: #999;
}

.detail-column .empty-data{
  margin-top: 2vh;
  padding: 1.5vh;
  height: 90%;
  padding-top: 30%
}

.list_item{
  background-color: white;
  margin-top: 2vh;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 1.5vh;
  cursor: pointer;
  -webkit-box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
  -moz-box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
  box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
}

.list_item:hover {
  /* background-color: aliceblue; */
  /* border: 1px solid black; */
}

.list_item:first-child{
  margin-top: 0vh;
}

/* .list_item_header{
  background-color: #A1B2FF;
  font-size: 1em;
  font-weight: bold;
} */

.list_item_header:hover h6{
  text-decoration: underline;
  color: #0056b3;
}

.list_item_collapse{
  /* background-color: #A1B282; */
  //padding-top: 2vh;
  font-size: 0.75em;
}

.accordion{
  margin-top: 2vh;
}

.detail_accordion_header {
  //background-color: #f9f9f99e !important;
  padding-left: 5% !important;
  padding-right: 5% !important;
}

 .detail_accordion_header div{
   display: table-cell;
   text-align: center;
   width: 100%;
   vertical-align: middle
 }

 .detail_accordion_header div:first-child {
   width: 50px;
 }

 .detail_accordion_header div:nth-child(2) {
   padding-left: 5%;
 }

.detail_accordion_header:hover {
  background-color: rgb(0 0 0 / 7%) !important;
  /* background-color: #9cd3f738; */
}

.list_item_badge div {
  padding: 0;
}

.list_item_badge div:first-child{
  margin: auto;
  line-height: 100%;
}

.list_item_badge div:nth-child(2){
  padding-left: 0.5em;
}

.detail_tab{
  margin-bottom: 2vh !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.detail_tab .nav-link {
  //padding-left: 0.5rem;
  //padding-right: 0.5rem;
  padding: .3rem .5rem;
}

.detail_tab .nav-link:nth-child(2) {
  width: 72%;
}

.tab-content{
  text-align: left;
  cursor: default;
}

.detail_table tr:first-child td {
  border-top: 0 !important;
}

.detail_table.main_info td:first-child {
  width: 40%;
}

.university_link{
  max-width: 2vh;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.detail_table.topics td:first-child {
  width: 90%;
}
.detail_table.topics td:nth-child(2) {
  text-align: right;
}

.detail_note {
  text-align: center;
  font-weight: lighter;
  color: grey;
  margin-top: 5px;
  margin-bottom: -10px;
}

.subjects_chart_no_data {
  text-align: center;
  color: #d4d4d4;
  font-style: italic;
  height: 3em;
  padding-top: 1em;
}

/* ----- MAP COLUMN ----- */

.map-column{
  padding-right: 0 !important;
  //padding-left: 0 !important;
}

.leaflet-container {
    height: 90vh;
    width: 100%;
}

 .popup_header{
    font-weight: 550;
    line-height: 1.2;
    font-size: .96rem;
    margin-bottom: .3rem;

}

.popup_content{
  font-size: 1em;
  font-weight: lighter;
  text-align: center;
}


/* ----- ADMIN PAGE ----- */

.admin_main{
  padding: 2vh;
  height: 90vh;
}

.login{
  width: 20em;
  margin: auto;
  margin-top: 10em;
  background-color: white;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 1.5vh;
  -webkit-box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
  -moz-box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
  box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
}

.login_error{
  width: 20em;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 4em;
}

.admin_container{
  min-height: 86vh;
  text-align: center;
  min-width: 600px;
  max-width: 1100px;
  margin: auto;
  background-color: white;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 3vh 6vh;
  -webkit-box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
  -moz-box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
  box-shadow: 6px 6px 10px 0px rgba(189,189,189,0.4);
}

.admin_main.scrollable {
  overflow-x: hidden;
  overflow-y: scroll;
  //max-height: 71.5vh;
}

.admin_main.scrollable::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.9);
	border-radius: 5px;
	background-color: #F5F5F5;
}

.admin_main.scrollable::-webkit-scrollbar {
	width: 5px;
}

.admin_main.scrollable::-webkit-scrollbar-thumb {
  border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
	background-color: #999;
}

#admin_nav {
    margin-bottom: 3em;
}

.admin_process_message{
  margin-top: 13vh;
}

.admin_section.scrollable {
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 71.5vh;
}

.admin_section.scrollable::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.9);
	border-radius: 5px;
	background-color: #F5F5F5;
}

.admin_section.scrollable::-webkit-scrollbar {
	width: 5px;
}

.admin_section.scrollable::-webkit-scrollbar-thumb {
  border-radius: 5px;
	-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,.3);
	background-color: #999;
}

.admin_header h3{
  margin-bottom: 2em;
  //font-size: 100%;
  color: grey;
}

.upload_form {
  text-align: left;
  padding-right: 1em;
  margin: auto;
  margin-top: 4em;
  max-width: 900px;
}

.upload_form .form-label:first-child{
  text-align: right;
}

.upload_form .form-label:last-child{
  padding-left: 0;
}

.upload_form h4{
  margin-bottom: 1em;
  text-align: center;
}

.upload_form h5{
  margin-top: 2em;
  padding-left: 5%;
  margin-bottom: 1em;
}

.col_formFile{
  margin: auto;
}

.upload_program{
  margin-top: 2em;
  padding: 1em 1em 1em 1em;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.upload_program:first-child{
  margin-top: 0em;
  padding-top: 2em;
}

.language-row-button{
  margin: auto;
}

.program_buttons{
  position: relative;
  top: 1em;
  left: 0em;
  //float:left;
  white-space: nowrap;
  z-index: 1000;
  margin-left: 0.3em;
}

.upload_buttons{
  text-align: center;
  margin-top: 0.5em;
}

.upload_button{
  margin: 1em;
}

.admin_overview_badge{
  margin: auto;
  width: 75px;
  text-align: center;
}

.admin_requests_action_column {
  width: 150px;
}

.admin_overview_action_column {
  width: 83px;
}

.header-download-info {
  font-weight: 200;
  font-size: 80%;
  /* font-style: italic; */
  color: gray;
}

.link-export:hover {
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
}
